import { mapGetters, mapActions } from 'vuex'
import { 
    cloneObject as utilsCloneObject, 
    orderBy as utilsOrderBy 
} from '@/utils/index'
export default {
    data() {
        return {
            applicationStatusesName: {
                0:"Создан",
                1:"Передано в Дирекцию",
                2:"Передано в Экспертный совет",
                3:"Отказано на рассмотрении",
                4:"Возвращено на доработку",
                5:"Подтвержден",
                6:"Отклонен",
                7:"Отозвана",
                55:"Назначен ВКС",   
            },
            quarterlyReportsStatusesName: {
                0:"Создан",
                1:"На расмотрении",
                2:"Подтвержден",
                3:"Отклонен",
                4:"Возвращено на доработку",
            },
        }
    },
    computed: {
        ...mapGetters({
            loadRunningGetter: 'loader/REQUEST_RUNNING',
            formOptions: 'classifiers/FORM_OPTIONS',
        }),
        loadRunning() {
            return this.loadRunningGetter < 0 ? 0 : this.loadRunningGetter;
        },
        isDev() {
            return process.env.NODE_ENV == "development";
        }
    },
    methods: {
        ...mapActions({}),
        cloneObject(object) {
            return utilsCloneObject(object);
        },
        orderBy(data, field, type = 'asc', byLength = false) {
            return utilsOrderBy(data, field, type, byLength);
        },
        changeQuery(key, value) {
            let query = {... {}, ...this.$route.query };
            if (value) {
                if (query[key] != value) {
                    query[key] = value;
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            } else {
                delete query[key];
                this.$router.push({ name: this.$route.name, params: this.$route.params, query });
            }
        },
        openFile(base64, filename) {
            let link = document.createElement("a");
            link.href = base64;
            link.download = filename;
            // link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        fullPath(path) {
            return process.env.VUE_APP_PUBLIC + path;
        },
        changeQuery(key, value) {
            let query = {... {}, ...this.$route.query };
            if (value) {
                if (query[key] != value) {
                    query[key] = value;
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            } else {
                if (query[key]) {
                    delete query[key];
                    this.$router.push({ name: this.$route.name, params: this.$route.params, query });
                }
            }
        },
        isOther(id){
            if(this.formOptions && this.formOptions.length > 0){
                var model = this.formOptions.find(item => item.id == id);
                if(model) return model.slug == 'other';
            }
            return false;
        },
        isCheckSlug(id, slug){
            if(this.formOptions && this.formOptions.length > 0){
                var model = this.formOptions.find(item => item.id == id);
                if(model) return model.slug == slug;
            }
            return false;
        },
        getTreeForSelectTree(list, parent_id){
            if(list && list.length){
                if(parent_id){
                    return list.filter(child => child.parent_id == parent_id).map(item => {
                        return {
                            parent_id: item.parent_id,
                            value: item.id,
                            label: this.$options.filters.t(item),
                            children: this.getTreeForSelectTree(list, item.id),
                        }
                    })
                }else{
                    return list.filter(parent => !parent.parent_id).map(item => {
                        return {
                            parent_id: item.parent_id,
                            value: item.id,
                            label: this.$options.filters.t(item),
                            children: this.getTreeForSelectTree(list, item.id),
                        }
                    })
                }
            }
            return [];
        },
        getMonthName(date){
            const monthNames = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь",
                "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"
            ];
            var result = new Date(date);
            return monthNames[result.getMonth()];
        },
        getDay(date){
            var result = new Date(date);
            return result.getDate();
        },
        getYear(date){
            var result = new Date(date);
            return result.getFullYear();
        },
        getYearRu(n){
            let str1 = this.$t('год');
            let str2 = this.$t('года');
            let str5 = this.$t('лет');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        getAge(n){
            let str1 = this.$t('год в');
            let str2 = this.$t('года в');
            let str5 = this.$t('лет в');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        getMonthRu(n){
            let str1 = this.$t('месяц');
            let str2 = this.$t('месяца');
            let str5 = this.$t('месяцев');
            return ((((n%10)==1)&&((n%100)!=11))?(str1):(((((n%10)>=2)&&((n%10)<=4))&&(((n%100)<10)||((n%100)>=20)))?(str2):(str5)))
        },
        experience(experience_month){
            if(experience_month){
                let years = experience_month/12;
                if(!Number.isInteger(years)){
                years = Math.floor(years);
                return {
                    year: years,
                    month: experience_month - (years*12),
                }
                }else{
                return {
                    year: years,
                    month: 0,
                }
                }
            }
            return null;
        },
        getPercentage(totalCount, count){
            if (isNaN(totalCount) || isNaN(count) || !count || !totalCount) {
              return 0
            }
            let result = ((count / totalCount) * 100)
            return Math.round(result * 100) / 100
        }
    }
};